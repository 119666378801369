'use client'

import { createContext, useCallback, useEffect, useState } from 'react'

import { client } from '@/sanity/lib/client'
import { calculateDiscount } from '@/utils/calculate-discounted-price'

export const CartContext = createContext({})

export const CartProvider = ({ children }: any) => {
    const [showCart, setShowCart] = useState(false)
    const [qty, setQty] = useState(1)
    const [cartItems, setCartItems] = useState<any[]>(() => {
        if (typeof window !== 'undefined') {
            const savedItems = localStorage.getItem('cartItems')
            return savedItems ? JSON.parse(savedItems) : []
        }
        return []
    })
    const [totalQuantity, setTotalQuantity] = useState(0)
    const [totalPrice, setTotalPrice] = useState(0)
    const [availableItems, setAvailableItems] = useState<{
        [id: string]: boolean
    }>({})

    const getAvailableItem = useCallback(
        async (id: string, quantity: number): Promise<boolean> => {
            try {
                const availableQuantity = await client.fetch(
                    `*[_type == "dance-class" && _id == $id] {
                    quantity,
                    reservedQuantity
                }`,
                    { id },
                    { cache: 'no-store' }
                )

                const available =
                    availableQuantity[0]?.quantity -
                    (availableQuantity[0]?.reservedQuantity || 0)

                return quantity < available
            } catch (error) {
                console.error('Error fetching available quantity:', error)
                return false
            }
        },
        []
    )

    const isItemAvailable = useCallback(
        (id: string) => {
            return availableItems[id] ?? true
        },
        [availableItems]
    )

    const updateTotals = useCallback(() => {
        const totalQuantity = cartItems.reduce(
            (acc, item) => acc + item.quantity,
            0
        )
        const totalPrice = cartItems.reduce((acc, item) => {
            const discount = calculateDiscount(item.price, item.quantity)
            const discountedPrice = item.price - discount
            return acc + discountedPrice * item.quantity
        }, 0)

        setTotalPrice(totalPrice)
        setTotalQuantity(totalQuantity)
    }, [cartItems])

    const checkAvailability = useCallback(async () => {
        const availabilityPromises = cartItems.map(async (item: any) => {
            const isAvailable = await getAvailableItem(item.id, item.quantity)
            return { id: item.id, isAvailable }
        })

        const availabilityResults = await Promise.all(availabilityPromises)
        const updatedAvailability: { [id: string]: boolean } = {}
        availabilityResults.forEach(({ id, isAvailable }) => {
            updatedAvailability[id] = isAvailable
        })

        setAvailableItems(updatedAvailability)
    }, [cartItems, getAvailableItem])

    useEffect(() => {
        checkAvailability()
    }, [checkAvailability])

    useEffect(() => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('cartItems', JSON.stringify(cartItems))
            updateTotals()
        }
    }, [cartItems, updateTotals])

    const incQty = (id: any) => {
        const item = cartItems.find((item) => item.id === id)
        if (item && isItemAvailable(id)) {
            setQty((prevQty) => prevQty + 1)
        }
    }

    const decQty = (id: any) => {
        const item = cartItems.find((item) => item.id === id)
        if (item && item.quantity > 1) {
            setQty((prevQty) => prevQty - 1)
        }
    }

    const addProduct = (product: any, quantity: number) => {
        const uniqueProductKey = `${product.id}-${product.datetime}-${product.city}`

        const checkProductInCart = cartItems.find(
            (item: any) =>
                `${item.id}-${item.datetime}-${item.city}` === uniqueProductKey
        )

        if (checkProductInCart) {
            const newQuantity = checkProductInCart.quantity + quantity

            const updatedCartItems = cartItems.map((cartProduct: any) => {
                if (
                    `${cartProduct.id}-${cartProduct.datetime}-${cartProduct.city}` ===
                    uniqueProductKey
                ) {
                    return {
                        ...cartProduct,
                        quantity: newQuantity,
                    }
                } else {
                    return cartProduct
                }
            })
            setCartItems(updatedCartItems)
        } else {
            product.quantity = quantity
            setCartItems([...cartItems, { ...product }])
        }
    }

    const toggleCartItemQty = (id: any, value: any) => {
        const index = cartItems.findIndex((product) => product.id === id)
        const updatedCartItems = [...cartItems]

        if (value === 'plus' && isItemAvailable(id)) {
            updatedCartItems[index] = {
                ...updatedCartItems[index],
                quantity: updatedCartItems[index].quantity + 1,
            }
        } else if (value === 'minus' && updatedCartItems[index].quantity > 1) {
            updatedCartItems[index] = {
                ...updatedCartItems[index],
                quantity: updatedCartItems[index].quantity - 1,
            }
        } else if (
            value === 'minus' &&
            updatedCartItems[index].quantity === 1
        ) {
            updatedCartItems.splice(index, 1)
        }
        setCartItems(updatedCartItems)
    }

    const onRemove = (product: any) => {
        const uniqueProductKey = `${product.id}-${product.datetime}-${product.city}`

        const newCartItems = cartItems.filter(
            (item) =>
                `${item.id}-${item.datetime}-${item.city}` !== uniqueProductKey
        )

        setCartItems(newCartItems)
    }

    const getTotalDiscount = () => {
        return cartItems.reduce((acc: number, item: any) => {
            const discount = calculateDiscount(item.price, item.quantity)

            return acc + discount * item.quantity
        }, 0)
    }

    const clearCart = () => {
        setCartItems([])
        setTotalQuantity(0)
        setTotalPrice(0)
        if (typeof window !== 'undefined') {
            localStorage.removeItem('cartItems')
        }
    }

    return (
        <CartContext.Provider
            value={{
                onRemove,
                toggleCartItemQty,
                totalPrice,
                totalQuantity,
                showCart,
                setShowCart,
                qty,
                incQty,
                decQty,
                cartItems,
                addProduct,
                getTotalDiscount,
                clearCart,
                isItemAvailable,
            }}
        >
            {children}
        </CartContext.Provider>
    )
}
